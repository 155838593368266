<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-4">
      {{ $t("af:application.flow.scoring_check.title") }}
    </p>
    <p class="mb-5">
      {{ $t("af:application.flow.scoring_check.subtitle") }}
    </p>

    <LoadingSkeleton v-if="!loaded" />

    <template v-else>
      <b-loading
        :is-full-page="true"
        v-model="waitingForDecision"
        :can-cancel="false"
      >
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-medium"
          custom-class="fa-spin"
        >
        </b-icon>
      </b-loading>
      <template v-if="decision === SCORE_DECISION.ENUM.MANUAL">
        <div class="scoring-info-card">
          <b-icon
            icon-pack="fa"
            icon="info-circle"
            type="is-blue"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{ $t("af:application.flow.scoring_check.not_ready.title") }}
          </p>
          <p>
            {{ $t("af:application.flow.scoring_check.not_ready.subtitle") }}
          </p>

          <p class="mt-5">
            {{ $t("af:application.flow.scoring_check.not_ready.message") }}
          </p>
        </div>
      </template>

      <template
        v-if="
          decision === SCORE_DECISION.ENUM.APPROVED ||
          decision === SCORE_DECISION.ENUM.MANUALLY_APPROVED
        "
      >
        <div class="scoring-info-card">
          <b-icon
            icon-pack="fa"
            icon="check-circle"
            type="is-green"
            size="is-large"
            class="is-pulled-left pb-2 mr-2"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{ $t("af:application.flow.scoring_check.approved.title") }}
          </p>
          <p>
            {{ $t("af:application.flow.scoring_check.approved.subtitle") }}
          </p>

          <p class="mt-5">
            {{ $t("af:application.flow.scoring_check.approved.message") }}
          </p>
        </div>

        <b-field class="mt-4">
          <b-button
            id="scoringButtonContinueFlow"
            type="is-success"
            size="is-large"
            :label="
              $t('af:application.flow.scoring_check.approved.button.continue')
            "
            :rounded="true"
            :inverted="true"
            icon-right="arrow-alt-circle-right"
            @click="continueFlow"
          ></b-button>
        </b-field>
      </template>

      <template
        v-if="
          decision === SCORE_DECISION.ENUM.REJECT ||
          decision === SCORE_DECISION.ENUM.MANUALLY_REJECTED ||
          decision === SCORE_DECISION.ENUM.DISALLOWED
        "
      >
        <div class="scoring-info-card">
          <b-icon
            icon-pack="fa"
            icon="times-circle"
            type="is-red"
            size="is-large"
            class="is-pulled-left pb-2 mr-2 is-red-icon"
          ></b-icon>

          <p class="has-text-weight-bold mb-0">
            {{ $t("af:application.flow.scoring_check.rejected.title") }}
          </p>
          <p>
            {{ $t("af:application.flow.scoring_check.rejected.subtitle") }}
          </p>

          <p class="mt-5">
            {{ $t("af:application.flow.scoring_check.rejected.message") }}
          </p>
        </div>

        <b-field class="mt-4">
          <b-button
            id="scoringButtonFinishApplication"
            type="is-danger"
            size="is-large"
            :label="
              $t('af:application.flow.scoring_check.rejected.button.continue')
            "
            :rounded="true"
            :inverted="true"
            icon-right="arrow-alt-circle-right"
            @click="finishApplication"
          ></b-button>
        </b-field>
      </template>
    </template>
  </div>
</template>

<script>
import { FLOW_POSITION, SCORE_DECISION } from "../../utils/const";
import LoadingSkeleton from "../LoadingSkeleton.vue";
import { mapActions } from "vuex";
import flowResultMixin from "../../mixins/flowResultMixin";

export default {
  name: "ApplicationFlowScoring",
  mixins: [flowResultMixin],
  components: { LoadingSkeleton },
  props: {
    isOnline: {
      type: Boolean,
      default: false,
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      SCORE_DECISION,
      loaded: false,
      poll: null,
      decision: null,
    };
  },
  computed: {
    waitingForDecision() {
      return !this.decision;
    },
  },
  async mounted() {
    await this.updateDecision();
    this.poll = window.setInterval(this.updateDecision, 15000);

    this.loaded = true;
  },

  beforeDestroy() {
    window.clearInterval(this.poll);
  },
  methods: {
    ...mapActions({
      savePosition: "loanApplication/savePosition",
    }),
    async updateDecision() {
      this.decision = await this.$store.dispatch(
        "loanApplication/fetchAndGetScoringDecision",
        this.applicationId
      );
    },
    async continueFlow() {
      if (this.isOnline) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.KYC_PREPARATION,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.OFFLINE_UNDER_REVIEW,
          currentFlowStepIndex: this.currentStepIndex,
        });
      }

      await this.$store.dispatch("loanApplication/fetchApplication");
      await this.$store.dispatch("myTodo/fetchCount");
    },
  },
};
</script>

<style scoped lang="scss">
.scoring-info-card {
  background-color: #f3f5f9;
  border-radius: 8px;
  padding: 1rem;
}
</style>
