var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.title"))+" ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.subtitle"))+" ")]),(!_vm.loaded)?_c('LoadingSkeleton'):[_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.waitingForDecision),callback:function ($$v) {_vm.waitingForDecision=$$v},expression:"waitingForDecision"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"sync-alt","size":"is-medium","custom-class":"fa-spin"}})],1),(_vm.decision === _vm.SCORE_DECISION.ENUM.MANUAL)?[_c('div',{staticClass:"scoring-info-card"},[_c('b-icon',{staticClass:"is-pulled-left pb-2 mr-2",attrs:{"icon-pack":"fa","icon":"info-circle","type":"is-blue","size":"is-large"}}),_c('p',{staticClass:"has-text-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.not_ready.title"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.not_ready.subtitle"))+" ")]),_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.not_ready.message"))+" ")])],1)]:_vm._e(),(
        _vm.decision === _vm.SCORE_DECISION.ENUM.APPROVED ||
        _vm.decision === _vm.SCORE_DECISION.ENUM.MANUALLY_APPROVED
      )?[_c('div',{staticClass:"scoring-info-card"},[_c('b-icon',{staticClass:"is-pulled-left pb-2 mr-2",attrs:{"icon-pack":"fa","icon":"check-circle","type":"is-green","size":"is-large"}}),_c('p',{staticClass:"has-text-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.approved.title"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.approved.subtitle"))+" ")]),_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.approved.message"))+" ")])],1),_c('b-field',{staticClass:"mt-4"},[_c('b-button',{attrs:{"id":"scoringButtonContinueFlow","type":"is-success","size":"is-large","label":_vm.$t('af:application.flow.scoring_check.approved.button.continue'),"rounded":true,"inverted":true,"icon-right":"arrow-alt-circle-right"},on:{"click":_vm.continueFlow}})],1)]:_vm._e(),(
        _vm.decision === _vm.SCORE_DECISION.ENUM.REJECT ||
        _vm.decision === _vm.SCORE_DECISION.ENUM.MANUALLY_REJECTED ||
        _vm.decision === _vm.SCORE_DECISION.ENUM.DISALLOWED
      )?[_c('div',{staticClass:"scoring-info-card"},[_c('b-icon',{staticClass:"is-pulled-left pb-2 mr-2 is-red-icon",attrs:{"icon-pack":"fa","icon":"times-circle","type":"is-red","size":"is-large"}}),_c('p',{staticClass:"has-text-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.rejected.title"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.rejected.subtitle"))+" ")]),_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t("af:application.flow.scoring_check.rejected.message"))+" ")])],1),_c('b-field',{staticClass:"mt-4"},[_c('b-button',{attrs:{"id":"scoringButtonFinishApplication","type":"is-danger","size":"is-large","label":_vm.$t('af:application.flow.scoring_check.rejected.button.continue'),"rounded":true,"inverted":true,"icon-right":"arrow-alt-circle-right"},on:{"click":_vm.finishApplication}})],1)]:_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }