<template>
  <div>
    <div
      class="flow-header py-6 px-5"
      :style="{
        backgroundImage: `url(${imageUrl})`,
      }"
    >
      <div class="offer-card py-4 px-4">
        <div class="offer-card__label">
          {{ $t("af:application.offer.title") }}
        </div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center offer-card-content"
        >
          <div class="is-flex offer-card-title" :style="{ gap: '8px' }">
            <PartnerLogo
              v-if="loanApplication.offerId"
              class="is-flex-shrink-0"
              hide-name
              :offerId="loanApplication.offerId"
            />
            <p class="has-text-weight-bold offer-name">
              {{ offer.calculation ? offer.calculation.offerName : "-" }}
            </p>
          </div>

          <div v-if="offer.calculation" class="details-holder is-flex-shrink-0">
            <div class="offer-detail mr-1">
              <p class="is-uppercase has-text-grey-icon mb-1">
                {{ $t("af:application.offer.details.amount") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ monify(offer.calculation.amount) }}
                {{ $t("af:application.offer.details.amount_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1">
                {{ $t("af:application.offer.details.duration") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ offer.calculation.duration }}
                {{ $t("af:application.offer.details.duration_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1">
                {{ $t("af:application.offer.details.thm") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ offer.calculation.thm.toFixed(2)
                }}{{ $t("af:application.offer.details.thm_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1">
                {{ $t("af:application.offer.details.interest_rate") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ offer.calculation.rate.toFixed(2)
                }}{{ $t("af:application.offer.details.interest_rate_unit") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-6 px-5">
      <section class="flow-content" v-if="!loaded">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <b-skeleton circle width="64px" height="64px"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton active></b-skeleton>
                <b-skeleton height="80px"></b-skeleton>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left">
                <a class="level-item">
                  <span class="icon is-small">
                    <b-skeleton></b-skeleton>
                  </span>
                </a>
                <a class="level-item">
                  <span class="icon is-small">
                    <b-skeleton></b-skeleton>
                  </span>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </section>
      <div class="flow-content">
        <ApplicationFlowIntro
          v-if="loanApplication.currentPosition === FLOW_POSITION.INTRO"
          :flow-config-steps="flowSteps"
          :application-id="loanApplication.id"
        />

        <ApplicationFlowStep
          v-if="
            loanApplication.currentPosition === FLOW_POSITION.FLOW &&
            currentStep
          "
          :step="currentStep"
          :flowId="loanApplication.flowId"
          :loanApplicationId="loanApplication.id"
        />

        <ApplicationFlowOverview
          v-if="loanApplication.currentPosition === FLOW_POSITION.OVERVIEW"
          :steps="flowSteps"
        />

        <ApplicationFlowScoring
          v-if="loanApplication.currentPosition === FLOW_POSITION.SCORING"
          :is-online="offer.data.online"
          :applicationId="loanApplication.id"
        />

        <ApplicationFlowKYCPreparation
          v-if="
            loanApplication.currentPosition === FLOW_POSITION.SIGN_PREPARATION
          "
        />

        <ApplicationFlowKYCChoose
          v-if="loanApplication.currentPosition === FLOW_POSITION.SIGN_CHOOSE"
          :position="loanApplication.currentPosition"
          :sigTypes="offer.data.sigTypes"
        />

        <ApplicationFlowWaitingRoom
          v-if="loanApplication.currentPosition === FLOW_POSITION.SIGN_WAITING"
          :position="loanApplication.currentPosition"
        />

        <ApplicationFlowContractCheck
          v-if="
            loanApplication.currentPosition === FLOW_POSITION.CONTRACT_CHECK
          "
          :position="loanApplication.currentPosition"
        />

        <ApplicationFlowUnderReview
          v-if="loanApplication.currentPosition === FLOW_POSITION.UNDER_REVIEW"
        />

        <ApplicationFlowOfflineUnderReview
          v-if="
            loanApplication.currentPosition ===
            FLOW_POSITION.OFFLINE_UNDER_REVIEW
          "
        />

        <ApplicationFlowResult
          v-if="loanApplication.currentPosition === FLOW_POSITION.RESULT"
          :status="loanApplication.currentStatusResult"
        />

        <ApplicationFlowOfflineResult
          v-if="
            loanApplication.currentPosition === FLOW_POSITION.OFFLINE_RESULT
          "
          :status="loanApplication.currentStatusResult"
        />

        <ApplicationPurchaseFlowResult
          v-if="
            loanApplication.currentPosition === FLOW_POSITION.PURCHASE_RESULT
          "
          :status="loanApplication.currentStatusResult"
        />

        <ApplicationFlowClosed
          v-if="loanApplication.currentPosition === FLOW_POSITION.CLOSED"
        />
      </div>
    </div>

    <portal to="application-flow-navigator-destination">
      <ApplicationFlowNavigator
        v-if="offer.data"
        :current-step-index="
          loanApplication.currentPosition === FLOW_POSITION.INTRO
            ? 0
            : loanApplication.currentFlowIndex
        "
        :flow-position="loanApplication.currentPosition"
        :application-id="loanApplication.id"
        :steps="flowSteps"
        :is-online="offer.data.online"
        :has-scoring="offer.data.scoringLive"
      />
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  CUSTOM_APPEARANCE,
  FLOW_POSITION,
  LOAN_STATUS,
  UI_CONFIGS,
} from "@/utils/const";
import { monify } from "@/utils/util";
import ApplicationFlowScoring from "../components/applicationflow/ApplicationFlowScoring.vue";

export default {
  name: "LoanApplicationFlow",
  title: "af:page.loan_application_flow.title",
  components: {
    ApplicationFlowScoring,
    ApplicationFlowResult: () =>
      import("@/components/applicationflow/ApplicationFlowResult.vue"),
    ApplicationFlowOfflineResult: () =>
      import("@/components/applicationflow/ApplicationFlowOfflineResult.vue"),
    ApplicationPurchaseFlowResult: () =>
      import("@/components/applicationflow/ApplicationPurchaseFlowResult.vue"),
    ApplicationFlowUnderReview: () =>
      import("@/components/applicationflow/ApplicationFlowUnderReview.vue"),
    ApplicationFlowOfflineUnderReview: () =>
      import(
        "@/components/applicationflow/ApplicationFlowOfflineUnderReview.vue"
      ),
    ApplicationFlowClosed: () =>
      import("@/components/applicationflow/ApplicationFlowClosed.vue"),
    ApplicationFlowContractCheck: () =>
      import("@/components/applicationflow/ApplicationFlowContractCheck.vue"),
    ApplicationFlowNavigator: () =>
      import("@/components/applicationflow/ApplicationFlowNavigator.vue"),
    ApplicationFlowIntro: () =>
      import("@/components/applicationflow/ApplicationFlowIntro.vue"),
    ApplicationFlowStep: () =>
      import("@/components/applicationflow/ApplicationFlowStep.vue"),
    ApplicationFlowKYCPreparation: () =>
      import("@/components/applicationflow/ApplicationFlowKYCPreparation.vue"),
    ApplicationFlowKYCChoose: () =>
      import("@/components/applicationflow/ApplicationFlowKYCChoose.vue"),
    ApplicationFlowWaitingRoom: () =>
      import("@/components/applicationflow/ApplicationFlowWaitingRoom.vue"),
    ApplicationFlowOverview: () =>
      import("@/components/applicationflow/ApplicationFlowOverview.vue"),
    PartnerLogo: () => import("@/components/PartnerLogo"),
  },
  data() {
    return {
      monify,
      FLOW_POSITION,
      LOAN_STATUS,
      poll: null,
      imageUrl: null,
    };
  },
  computed: {
    ...mapGetters({
      offer: "loanApplication/getActiveOffer",
      loanApplication: "loanApplication/getActiveApplication",
      loaded: "loanApplication/loaded",
      flowSteps: "loanApplication/flow",
      fileGetter: "drawer/getFile",
      getImageDetailsForOffer: "image/getImageDetailsForOffer",
    }),
    currentStep() {
      return this.flowSteps.find(
        (fcs) => fcs.rowIndex === this.loanApplication.currentFlowIndex
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/fetch");
    if (!this.$store.getters["profile/isInState"]("IN_FLOW")) {
      await this.$router.push(this.$store.getters["profile/getBackPath"]);
      return;
    }
    await this.$store.dispatch("loanApplication/loadEverything");
    this.poll = window.setInterval(() => {
      this.$store.dispatch("loanApplication/pollStatus");
    }, 30000);

    if (
      this.$store.getters["config/getAutoloadConfigValue"](
        UI_CONFIGS.APPEARANCE
      ) !== CUSTOM_APPEARANCE.MBH
    ) {
      await this.$store.dispatch("image/fetchImage");
      const { backgroundImage } = this.getImageDetailsForOffer(
        this.mortgageApplication.offerId
      );

      if (!this.fileGetter(backgroundImage)) {
        await this.fetchFile(backgroundImage);
      }

      this.imageUrl = URL.createObjectURL(this.fileGetter(backgroundImage));
    } else {
      this.imageUrl = "../assets/images/mbh_desktop_bg.png";
    }
  },
  beforeDestroy() {
    window.clearInterval(this.poll);
  },
  methods: {
    ...mapActions({
      fetchFile: "drawer/download",
    }),
  },
};
</script>

<style scoped lang="scss">
.flow-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.flow-content {
  max-width: 560px;
  margin: 0 auto;
}

.offer-card {
  max-width: 560px;
  margin: 0 auto;
  position: relative;

  background: #ffffff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  &__label {
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 10px;
    color: white;
    background: #051c3a;
    padding: 4px 8px;
    border-radius: 4px 4px 0 0;
  }

  .offer-detail {
    background: #f3f5f9;
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;

    p:first-of-type {
      font-size: 10px;
    }

    p:last-of-type {
      font-size: 12px;
    }
  }
}

.offer-card-title {
  flex-direction: row;
}

.offer-card-content {
  flex-direction: column;
}

.details-holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mbh {
  .offer-detail {
    background: none;
    text-align: left;
  }

  .offer-card {
    max-width: 660px;
    border-radius: 32px;
    padding: 16px !important;

    &__label {
      left: 32px;
    }
  }

  .flow-content {
    max-width: 660px;
  }

  .has-text-grey-icon {
    color: #616161 !important;
    font-size: 13px !important;
  }

  .detail-amount {
    color: #424242 !important;
    font-size: 16px !important;
  }

  .offer-name {
    font-size: 20px;
    color: #051c3a;
    line-height: 28px;
  }

  .flow-header {
    background-image: url("../assets/images/mbh_desktop_bg.png") !important;
  }
}

@media only screen and (min-width: 769px) {
  .offer-detail {
    p:first-of-type {
      font-size: 12px;
    }

    p:last-of-type {
      font-size: 14px;
    }
  }
  .offer-card-title {
    flex-direction: column !important;
  }

  .offer-card-content {
    flex-direction: row !important;
  }

  .mbh {
    .offer-card {
      padding: 24px 32px 24px 32px !important;
    }
  }

  .details-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
