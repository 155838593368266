export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSuccess() {
      return this.status === "CLOSED_APPROVED";
    },
  },
  methods: {
    async finishApplication() {
      try {
        await this.$store.dispatch("loanApplication/finish");
        await this.$store.dispatch("purchase/hasActivePurchase");
        await this.$router.push("/user/applications");
      } catch (e) {
        console.error(e);
      }
    },
    async finishMortgageApplication() {
      try {
        await this.$store.dispatch("mortgageApplication/finish");
        await this.$router.push("/user/mortgage-applications");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
